@import '../../../../styles.scss';
//@use '../../../../../node_modules/@angular/material/index' as mat;
:root {
  --appBackground: #ffffff;
  --primaryColor: #008a00;
  --primaryAccentColor: #1a5336;
  --secondaryAccentColor: #535353;
  --tertiaryAccentColor: #9e9e9e;
  --backgroundShade: #dadada;
  --accentBackground: #f0f4f6;
  --headerBackground: #f7f7f7;
  --inactiveButton: #f2f2f2;
  --alertsErrors: #ff0000;
  --checkbox: #3369ff;
  --progressBar: #00bb66;
  --webInitialBackground: #f7f7f7;
  --primaryColorSemiTransparent: #008a005c;
  --appCameraBackground: #000000;
  --successBar: #00bb00;
  --tdGray: #f9f9f9;
  --tdGraySecondary: #f0f0f0;
  --tdGrayTertiary: #1c1c1c;
  --tdEmptyState: #767676;

  // TODO REMOVE
  --blue-1: #e7edf3;
  --blue-2: #dde6ee;
  --blue-3: #cfd8e0;
  --blue-4: #c7d2dd;
  --blue-5: #3183c8;
  --blue-6: #1a4971;

  --gray-0: #ffffff;
  --gray-1: #f8f9fa;
  --gray-2: #f1f3f5;
  --gray-3: #e7ebee;
  --gray-4: #dee2e6;
  --gray-5: #cfd4da;
  --gray-6: #adb5bd;
  --gray-7: #868e96;
  --gray-8: #485056;
  --gray-9: #343a40;
  --gray-10: #21242a;
  --gray-11: #101214;
  --gray-12: #000000;

  --yellow-1: #face0080;
  --red: red;
  --white: white;
  --gray: #cccccc;
  --orange: #ff9500;
  --mdc-filled-text-field-error-caret-color: #d91a0d !important;
  --mdc-filled-text-field-error-label-text-color: #d91a0d !important;
  --mdc-filled-text-field-error-focus-label-text-color: #d91a0d !important;
  //--mdc-filled-text-field-error-focus-active-indicator-color: $custom-typography--mdc-filled-text-field-error-hover-active-indicator-color:
  //  #d91a0d !important; // TODO CHECK ON MIGRATION
}
:root {
  --font-weight-500: 500;
  --font01: 'tdgraphik';
  --fontSizeField: 0.938rem;
  --fontSizeForError: 0.75rem;
  --fontWeightForError: 300;
  --fontSizeForLink: 0.875rem;
  --main-font-family: var(--font01), 'poppins', 'roboto', 'Helvetica Neue',
    sans-serif;
  --fontSizeForSectionTitle: 1.125rem;
  --fontSizeForSectionSubTitle: 0.938rem;
  --fontSizeForBubble: 0.625rem;
  --fontSizeForListTitle: 0.813rem;
  --fontSizeForListSentence: 0.75rem;
  --fontLetterSpacing: 0.026em;
  --TdFontSizeForListTitle: 0.938rem;
  --TdFontSizeForSectionSubTitle: 0.813rem;
}
//@import '../../../../scss/variables';
//@import '../../../../scss/helpers';
///@import '../../../../scss/media-queries';
//@import '../../../../scss/ckeditor5';
//@import '../../../../scss/quill';
//@import '../../../../styles-for-material';

//@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$sdrw-ng-web-primary: mat.define-palette(mat.$indigo-palette); // TODO CHECK ON MIGRATION
//$sdrw-ng-web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400); // TODO CHECK ON MIGRATION

// The warn palette is optional (defaults to red).
//$sdrw-ng-web-warn: mat.define-palette(mat.$red-palette); // TODO CHECK ON MIGRATION

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
//$sdrw-ng-web-theme: mat.define-light-theme(
//  (
//    color: (
//      primary: $sdrw-ng-web-primary,
//      accent: $sdrw-ng-web-accent,
//      warn: $sdrw-ng-web-warn,
//    ),
//  )
//); // TODO CHECK ON MIGRATION

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-legacy-component-themes($sdrw-ng-web-theme); // TODO CHECK ON MIGRATION

//@include mat.all-component-themes($sdrw-ng-web-theme); // TODO CHECK ON MIGRATION

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  font-family: var(--main-font-family) !important;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  background-color: var(--appBackground) !important;
  @include tablet {
    background-color: var(--webInitialBackground);
  }
}

body {
  margin: 0;
}

h1 {
  outline: none;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.font-error {
  font-size: var(--fontSizeForError);
  color: var(--alertsErrors);
  font-weight: var(--fontWeightForError);
  font-family: var(--fontFamilyForError);
}

.mat-progress-spinner circle .main {
  stroke: var(--appBackground) !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--primaryColor) !important;
}

.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: var(--inactiveButton);
  color: var(--primaryAccentColor);
}

/* .mat-form-field .mat-form-field-label {
  height: 100%;
} */

.mat-form-field-label {
  color: var(--tertiaryAccentColor) !important;
}

.mat-focused .mat-form-field-label {
  color: var(--primaryColor) !important;
}

.mat-focused .mat-form-field-ripple {
  background-color: var(--primaryColor) !important;
}

.mat-input-invalid .mat-input-placeholder {
  color: var(--alertsErrors);
}

.mat-input-invalid .mat-input-ripple {
  background-color: var(--alertsErrors);
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--primaryColor);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--primaryColor);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primaryColor);
}

.mat-calendar-body-selected {
  background-color: var(--primaryColor);
  color: var(--appBackground);
}

.mdc-linear-progress__buffer {
  background-color: var(--backgroundShade) !important;
}

.primary-progress-bar {
  color: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}

.danger-progress-bar {
  color: var(--alertsErrors) !important;
  background-color: var(--alertsErrors) !important;
}

.success-progress-bar {
  color: var(--successBar) !important;
  background-color: var(--successBar) !important;
}

.main-progress-bar {
  color: var(--gray-7) !important;
}

.sd-nack-bar,
.mat-tooltip {
  font-family: var(--main-font-family) !important;
  background-color: var(--primaryAccentColor);
}

.mat-option,
.mat-select-value {
  font-family: var(--main-font-family) !important;
  min-height: 1.6rem !important;
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled)
  .mdc-switch__shadow {
  background-color: var(--primaryColor);
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled)
  .mdc-switch__track::after {
  background-color: var(--tertiaryAccentColor) !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  fill: var(--appBackground) !important;
}

.mdc-switch:enabled .mdc-switch__track::before {
  background-color: var(--tertiaryAccentColor) !important;
}

.mdc-switch:enabled .mdc-switch__shadow {
  box-shadow:
    0 2px 1px -1px #0003,
    0 1px 1px #00000024,
    0 1px 3px #0000001f !important;
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background: var(--appBackground) !important;
}

//TODO REMOVE PAGE CLASS
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include tablet {
    background: var(--primaryColor);
    background: linear-gradient(
      142deg,
      var(--primaryColor) 0%,
      var(--primaryColorSemiTransparent) 100%
    );
    width: 100%;
    min-height: 100vh;
  }
}

.login-content {
  background-color: var(--appBackground);
  padding: 30px;
  overflow-x: hidden;

  @include tablet {
    border: solid 1px var(--backgroundShade);
    border-radius: 6px;
    margin: 20px;
    max-width: 500px;
  }
}

.base-dialog {
  position: relative;
  max-width: 500px;
  max-height: 80vh;

  app-alert-box {
    margin: 0 -40px;
    width: 100%;
  }

  .base-dialog-close-button {
    position: absolute;
    top: -40px;
    right: -20px;
    color: var(--primaryColor);
  }

  .base-dialog-title {
    font-family: var(--main-font-family);
    color: var(--primaryColor);
    font-size: 1.125rem;
    text-align: center;
    font-weight: 600;
    margin: 20px auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .base-dialog-content {
    font-family: var(--main-font-family);
  }

  .base-dialog-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    app-sd-flat-button {
      width: 100%;
    }
  }

  .base-dialog-multiple-buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 36px 36px;
    grid-row-gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    grid-template-areas:
      'primary'
      'secondary';
    //height: 102px;

    @include tablet {
      height: auto;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 36px;
      grid-column-gap: 10px;
      grid-template-areas: 'secondary primary';
    }

    app-sd-flat-button {
      width: 100%;
    }

    app-sd-flat-button.secondary {
      grid-area: secondary;
    }

    app-sd-flat-button.primary {
      grid-area: primary;
    }
  }
}

.home-views-template {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .home-views-container {
    .home-views-header {
      margin: 60px 0 30px;
    }

    .home-views-content {
      height: calc(100vh - 190px);
      margin: 0;
      overflow: auto;
    }

    .box-style {
      background-color: var(--appBackground);
      border-radius: 6px;
      border: solid 1px var(--backgroundShade);
      min-height: calc(100vh - 192px);
    }
  }
}

.home-view-spinner {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mat-button {
  min-width: 0 !important;
}
//TODO REMOVE PAGE CLASS
.sd-icon-button {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: var(--appBackground) !important;
  border-radius: 4px !important;
  border: var(--backgroundShade) solid 1px !important;
  height: 36px;
  width: 36px;
  padding: 0 !important;

  .sd-icon-button-icon {
    background-color: var(--primaryColor);
    width: 14px;
    height: 14px;
  }
}

.spacer {
  flex: 1;
}

.hidden {
  display: none;
}

.visible {
  display: initial;
}

.invisible {
  opacity: 0;
  pointer-events: none;
}

.content-headers-back-button {
  color: var(--primaryColor);
  background-color: var(--appBackground);
  font-family: var(--main-font-family);
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  height: 36px !important;

  mat-icon {
    margin-bottom: 2px;
    width: 20px;
  }
}

.right-menu-tooltip {
  margin-right: 0 !important;
}

.left-menu-tooltip {
  margin-left: 0 !important;
}

.border {
  border-bottom: var(--backgroundShade) 1px solid;
  height: 1px;
}

.no-padding-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 0 !important;
}

.spinner-progress-text {
  font-family: var(--main-font-family);
  font-size: 15px;
  font-weight: 700;
  color: var(--secondaryAccentColor);
  margin-right: 5px;
}

.estate-summary-chart .chart-legend .legend-labels {
  background: none !important;
  text-align: center !important;
  margin-top: -60px;
}

.sd-text-button {
  color: var(--primaryColor);
  text-decoration: none;
  text-align: center;
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  cursor: pointer;
}
// Drag and Drop Record files.
.record-upload-snackbar {
  background-color: var(--appBackground);
  height: 4rem !important;
  display: block;
}
// Overlay container snackbar info request drag and drop specific html position.
.drag-drop-overlay-container-snackbar {
  position: absolute;
}
// reminders list item
.reminder-list-item-info-sentence {
  span {
    &.date.bold,
    &.frequency.bold,
    &.iteration.bold {
      font-weight: bold;
      color: var(--secondaryAccentColor);
    }
  }
}

.sd-container-fixed {
  min-width: 100%;
  position: fixed;
  z-index: 901;
  top: 2.625rem;

  @include desktop {
    min-width: auto;
    width: calc(100% - 12.5rem);
  }
}

.custom-dialog-container-for-reminders-form-dialog-a11y {
  max-height: 95%;
  max-width: 95vw !important;

  @include desktop {
    max-width: 90vw;
    max-height: 85%;
  }
}

.custom-dialog-container-for-reminders-form-dialog-a11y
  .mat-mdc-dialog-container {
  padding-bottom: 0 !important;
}

.custom-dialog-form-licenses-mobile {
  max-width: 90vw;

  @include desktop {
    width: 70vw !important;
  }
  @include tablet {
    width: 80vw;
  }
}

//TODO REMOVE THIS WHEN THE THEMING IS UPDATED
.mat-mdc-dialog-container .mdc-dialog__surface {
  display: block;
  padding: 0px 0;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: unset;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.mat-mdc-dialog-container .mdc-dialog__title::before {
  display: none;
}

.mat-mdc-tooltip {
  color: var(--appBackground);
  background-color: var(--primaryAccentColor);
  font-family: var(--main-font-family) !important;
  font-size: 75%;
  margin-left: 0.375em;
  cursor: pointer;
  flex-wrap: nowrap;
  font-size: 75%;
  border-radius: 3%;
}

.mat-mdc-tooltip-trigger .tooltip-container .ng-star-inserted {
  color: var(--appBackground);
  background-color: var(--primaryAccentColor);
  font-family: var(--main-font-family) !important;
  margin-left: 0.375em;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.mat-flat-button {
  border-radius: 0 !important;
}

.sd-main-content-template {
  padding: 1.25em 0 0 0 !important;
  max-width: 1280px !important;
}

//TD STYLES

.sd-backdrop-grayed {
  background-color: rgba(0, 0, 0, 0) !important;
}

.sd-content-header .sd-content-header-title {
  font-family: var(--main-font-family);
  font-size: 1.5rem;
  font-weight: 500 !important;
  color: var(--primaryAccentColor);
  margin: 1em 0 !important;
}

.sd-content-template-content {
  overflow: hidden !important;
}

.queue-window {
  position: fixed !important;
}

//PLANS

.plan-list-item .plan-list-item-name {
  font-size: var(--TdFontSizeForListTitle) !important;
  font-weight: 300 !important;
  color: var(--tdGrayTertiary) !important;
}

.plan-list-item .plan-list-item-time .last-modified,
.plan-list-item .plan-list-item-time .at {
  font-size: var(--TdFontSizeForSectionSubTitle) !important;
  font-weight: 300 !important;
  color: var(--tdGrayTertiary) !important;
}

.plan-list-item .plan-list-item-time .by,
.plan-list-item .plan-list-item-time .date {
  font-size: var(--TdFontSizeForSectionSubTitle) !important;
  font-weight: 500 !important;
  color: var(--tdGrayTertiary) !important;
}

.plan-list-item-grid {
  padding: 1.5em 0 1.2em 0 !important;
}

//RECORDS

.record-item-header-sentence-container,
.record-item-header-top-section-name,
.sd-content-header .sd-content-header-title {
  color: var(--tdGrayTertiary) !important;
}

.record-item-header .record-item-header-sentence-container .at,
.record-item-header .record-item-header-sentence-container .last-modified {
  font-size: var(--TdFontSizeForSectionSubTitle) !important;
  font-weight: 300 !important;
  color: var(--tdGrayTertiary) !important;
}

.record-item-header .record-item-header-sentence-container .by,
.record-item-header .record-item-header-sentence-container .date {
  font-size: var(--TdFontSizeForSectionSubTitle) !important;
  font-weight: 500 !important;
  color: var(--tdGrayTertiary) !important;
}

.records-list-item-grid {
  padding: 1.5em 0 1.2em 0 !important;
}

.records-list-view .records-list-item-name {
  font-size: var(--TdFontSizeForListTitle) !important;
  font-weight: 300 !important;
  color: var(--tdGrayTertiary) !important;
}

.records-list-view .records-list-item-time .last-modified,
.records-list-view .records-list-item-time .at {
  font-size: var(--TdFontSizeForSectionSubTitle) !important;
  font-weight: 300 !important;
  color: var(--tdGrayTertiary) !important;
}

.records-list-view .records-list-item-time .by,
.records-list-view .records-list-item-time .date {
  font-size: var(--TdFontSizeForSectionSubTitle) !important;
  font-weight: 500 !important;
  color: var(--tdGrayTertiary) !important;
}

.records-list-view
  .records-list-item-container:last-of-type
  .records-list-item {
  border-bottom: none !important;
}

.plans-main-view-list app-td-plan-list-item-a11y:last-child .plan-list-item {
  border-bottom: none !important;
}

app-sd-infinite-list app-td-records-list-item:last-of-type .records-list-item {
  border-bottom: none !important;
}

//REMINDERS

.reminder-list-item-info {
  padding: 1.5em 0 1.2em 0 !important;
  align-items: center !important;
}

.reminder-list-item-info .reminder-list-item-info-title {
  font-size: var(--TdFontSizeForListTitle) !important;
  font-weight: 300 !important;
  color: var(--tdGrayTertiary) !important;
}

.reminder-list-item-info .reminder-list-item-info-sentence {
  font-size: var(--TdFontSizeForSectionSubTitle) !important;
  font-weight: 300;
  color: var(--tdGrayTertiary) !important;
}

.finished .reminder-list-item-info-sentence {
  color: var(--tertiaryAccentColor) !important;
}

.reminder-list-item-info .reminder-list-item-info-sentence {
  line-height: unset !important;
}

.reminders-list
  app-reminders-list-item-a11y:last-of-type
  .reminder-list-item-info {
  border-bottom: none !important;
}

.reminder-list-item-info .reminder-list-item-info-sentence strong {
  font-weight: 500 !important;
}

//RELATED RECORDS

.related-records-tags-container h3 {
  font-size: var(--TdFontSizeForListTitle) !important;
  font-weight: 300 !important;
  color: var(--tdGrayTertiary);
}

.related-records-tags-container .related-records-option {
  padding: 1em 0 0.6em 0 !important;
  justify-content: space-between;
}

.related-records-list-item-time .last-modified,
.related-records-list-item-time .at {
  font-size: var(--TdFontSizeForSectionSubTitle) !important;
  font-weight: 300 !important;
  color: var(--tdGrayTertiary) !important;
}

.related-records-list-item-time .by,
.related-records-list-item-time .date {
  font-size: var(--TdFontSizeForSectionSubTitle) !important;
  font-weight: 500 !important;
  color: var(--tdGrayTertiary) !important;
}

.related-records-tags-container app-related-records-tags-a11y:last-of-type {
  border-bottom: none !important;
}

.en .mat-badge-content {
  background-color: var(--orange) !important;
  top: 1rem !important;
  right: 6.7em !important;
  &:focus {
    outline-color: var(--primaryAccentColor) !important;
    outline-style: dotted !important;
  }
}

.fr .mat-badge-content {
  background-color: var(--orange) !important;
  top: 1rem !important;
  right: 6.7em !important;
  &:focus {
    outline-color: var(--primaryAccentColor) !important;
    outline-style: dotted !important;
  }
}

.mat-badge-after .mat-badge-content {
  left: unset !important;
}

@import '../scss/td-file-history-section.scss';
@import '../scss/td-file-history-section-item.scss';
@import '../scss/td-timeline.scss';

::ng-deep input::placeholder {
  color: var(----gray-9) !important;
  font-size: 0.75rem !important;
}

.signature-workflow-dialog {
  width: 95vw !important;
  max-width: 95vw !important;
  height: 95vh !important;
  max-height: 95vh !important;
  overflow: hidden !important;

  .sd-dialog-template-header {
    padding: 15px 24px 11px 24px !important;
  }

  .mat-mdc-dialog-content {
    max-height: 95vh !important;
  }
}
