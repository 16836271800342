.timeline-note-item-container .timeline-note-item-grid .note {
  font-size: var(--TdFontSizeForListTitle) !important;
  font-weight: 300 !important;
  color: var(--tdGrayTertiary) !important;
}

.timeline-note-item-container .pin {
  display: none !important;
}

.timeline-note-item-container .timeline-note-item-grid strong {
  font-size: var(--TdFontSizeForSectionSubTitle) !important;
  font-weight: 500 !important;
  color: var(--tdGrayTertiary) !important;
}

.timeline-note-item-container .timeline-note-item-grid {
  grid-template-columns: 4fr 1fr !important;
  grid-template-areas:
    'note date'
    'note actions' !important;
}
