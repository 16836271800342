app-file-history-section-item .file-history-section-item-grid {
  padding: 1.5em 0 1.2em 0 !important;
}

app-file-history-section-item
  .file-history-section-item-grid
  .file-list-item-name {
  font-size: var(--TdFontSizeForListTitle) !important;
  font-weight: 300 !important;
  color: var(--tdGrayTertiary);
}

app-file-history-section-item
  .file-history-section-item-grid
  .file-history-section-item-by
  .last-modified,
app-file-history-section-item
  .file-history-section-item-grid
  .file-history-section-item-by
  .at {
  font-size: var(--TdFontSizeForSectionSubTitle) !important;
  font-weight: 300 !important;
  color: var(--tdGrayTertiary) !important;
}

app-file-history-section-item
  .file-history-section-item-grid
  .file-history-section-item-by
  .by,
app-file-history-section-item
  .file-history-section-item-grid
  .file-history-section-item-by
  .date {
  font-size: var(--TdFontSizeForSectionSubTitle) !important;
  font-weight: 500 !important;
  color: var(--tdGrayTertiary) !important;

  strong {
    font-weight: 500 !important;
  }
}

.file-history-section-item .file-history-section-item-grid {
  grid-template-rows: auto !important;

  @include desktop {
    grid-template-rows: auto 22px;
  }

  @include tablet {
    grid-template-rows: auto 22px;
  }
}

// TODO working here
.file-history-section-item-grid-checkbox {
  @extend .file-history-section-item-grid;

  grid-template-columns: 36px 3fr auto;
  grid-template-areas:
    'image title displaytype'
    'image by actions';

  .file-history-section-item-checkbox {
    grid-area: checkbox;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  @include desktop {
    grid-template-columns: 36px 3fr auto;
    grid-template-areas:
      'image title displaytype'
      'image by actions';

    .file-history-section-item-checkbox {
      grid-area: checkbox;
      display: none;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
