@import 'media-queries';

:root {
  --column-gap-default: 0.5em;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;

  @include tablet {
    width: 100%;
    overflow: hidden;
    text-overflow: none;
    display: block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: none;
    white-space: normal;
  }

  @include desktop {
    width: 100%;
    overflow: hidden;
    text-overflow: none;
    display: block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: none;
    white-space: normal;
  }
}

.truncate-one-line {
  width: 9.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;

  @include tablet {
    width: 100%;
    overflow: hidden;
    text-overflow: none;
    display: block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: none;
    white-space: normal;
  }

  @include desktop {
    width: 100%;
    overflow: hidden;
    text-overflow: none;
    display: block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: none;
    white-space: normal;
  }
}

.cdk-overlay-container .mat-mdc-select-panel {
  min-width: 7em;
  min-width: calc(100% + 2em) !important;
  max-width: 17.5em;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 !important;
  max-height: 16em;
  min-width: 100%;
  border-radius: 4px;
  outline: 0;
  box-shadow:
    0 2px 4px -1px var(--backgroundShade),
    0 4px 5px var(--backgroundShade),
    0 1px 10px var(--backgroundShade);
  background: var(--appBackground);
}
.cdk-overlay-container .mat-mdc-select-panel .mat-mdc-option-active {
  background: var(--backgroundShade);
}

.cdk-overlay-container .cdk-overlay-pane .mat-mdc-option {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  padding-left: 1em;
  padding-right: 1em;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  min-height: 3em;
}

.sd-word-break-keep-all {
  word-break: keep-all;
  width: 3em;
  display: block;
  line-height: 0.9em;
  padding-top: 0.25em;
}
