.file-history-section-content
  app-file-history-section-item:last-of-type
  .file-history-section-item {
  border-bottom: none !important;
}
.record-form-files-tab-list
  app-file-history-section-item:last-of-type
  .file-history-section-item {
  border-bottom: none !important;
}
